<template>


  <div class="pb">
  
    <div class="headerow"> 

      <h1>
        Shorts
      </h1> 

      <GeneralSearchKeyword
        placeholder="Search Breeders & Nutrients"
        @choose="chooseTagKeyword"
      /> 
 
      <UiSort 
        @choose="chooseSort"
        :val="sort"    
        :options="[
          {id:'create', name: 'Create'},
          {id:'likes', name: 'Likes'},      
        ]"
      />

      <GeneralSearchTags 
        :tags="tags" 
        :tagsselected="tagsSelected" 
        :tagsdefault="tags" 
        :tagscount="shortsData?.items_tag"    
        :tag-as-link="true"
        :tag-link="'/shorts'"
        :modify-path="true"
        :is-show-counts="false"
        @choose="chooseTags"
        @unchoose="unchooseTags"
      />

    </div>

    <br>

    <SkShorts v-if="shortsDataStatus === 'pending' && start == 0" />

    <div 
      v-else-if="shortsData?.items?.length"
      class="videolist">
        <GeneralVideoShortLink 
          v-for="(opt, ind) in shortsData.items" 
          :key="ind"
          :cover="opt.item_photo.size_m"
          :name="opt?.title ??opt.item_diary?.name"
          :faza="opt.item_week?.faza"
          :week="opt.item_week?.days"
          :is-moderated="opt.is_moderated"
          :is-published="opt.is_published"
          :is-banned="opt.is_banned"
          :is-ready="opt.is_ready"
          :link="'/shorts/'+ opt.id"
          :video-id="opt.id"
          :author-id="opt.item_user.id"
          @click="click($event, ind)"
        />
    </div>

    <UiEmpty
      v-else
      />
 

    <ClientOnly>        
      <UiInfinityScroll 
        v-if="shortsData?.items?.length >= limit"
        :is-loading="shortsDataStatus === 'pending'"
        ref="elInfinityScroll"/>
    </ClientOnly>
 

    <ClientOnly>
      <Teleport to="#app"> 
        <div 
          v-if="isShowPlayer"
          class="over-player-pc">
          <div            
            class="over-player"    
            >        
              <GeneralShortsPageWithPlayer
                :list="shortsData?.items.slice(playerStartFrom)"
                :close-type="'close'"
                @close="hidePlayer"
                @loadMore="loadMore"
              />
          </div>
        </div>
      </Teleport>
    </ClientOnly>

    

  </div>
</template>



<script setup lang="ts">

import { TagsShorts, getTagsKeys, getTagsSimple, getTagsKeyword, getTagsSimpleTrKey } from '@/types/other'

import { useIntersectionObserver } from '@vueuse/core'
import { watchArray } from '@vueuse/core'

const route = useRoute();
const start = ref(0)
const limit = ref(20)
const isEnded = ref(false)
const { $api, $patcher } = useNuxtApp()
const { t } = useI18n()
const elInfinityScroll = ref(null)
const sort = ref('create')
const tags = ref(structuredClone(TagsShorts))
const tagsSelected = ref(route.params['tag']?.length ? route.params['tag'] : ( route.query?.tags ? route.query.tags.split(",") : []))
const isShowPlayer = ref(false)
const playerStartFrom = ref(0)


const click = function(e, ind){
  showPlayer();
  playerStartFrom.value = ind
}

const setUrl = () => {
  if(import.meta.client)
    useRouteHistory().push('/shorts');
}
  
const showPlayer = function(){
  isShowPlayer.value = true;
  useTopbarHiddenMobile().value = true;
  useWarningMigrateHidden().value = true;
  useNavbarThemeRequired().value = 'dark';
}

const hidePlayer = function(){
  isShowPlayer.value = false;
  useTopbarHiddenMobile().value = false;
  useWarningMigrateHidden().value = false;
  useNavbarThemeRequired().value = null;
  setUrl();
}


const clearPagination = function(){     
  start.value = 0;  
}

const chooseSort = function(selected_sort){   
  sort.value = selected_sort;    
  clearPagination();  
}


const chooseTagKeyword = function(tag){     
  if(tagsSelected.value.indexOf('all') != -1)
    tagsSelected.value = [];
  tagsSelected.value.push(tag.tag)  
  clearPagination();        
} 


const unchooseTags = function(selected){  
  console.log('unchooseTags');
  console.log(selected);
  console.log(tagsSelected.value);
  tagsSelected.value = tagsSelected.value.filter((item) => item != selected);
  console.log(tagsSelected.value);
}        

const chooseTags = function(selected){          
  tagsSelected.value.push(selected);      
  clearPagination();    
}


const loadData = async function() {   
 
  const response = await $api.getShorts({
    start: start.value,
    limit: limit.value,
    sortable: sort.value,
    q: getTagsKeyword(tags.value, tagsSelected.value),
    tagsall: getTagsKeys(tags.value),
    tags: getTagsSimple(tags.value, tagsSelected.value)
  })  
 
  return response;  
}


const loadMore = async function() {    
  start.value += limit.value;
}


const { status: shortsDataStatus,  data: shortsData } = await useLazyAsyncData('shortsData', async () => await loadData())
 

watchArray([start, sort, tagsSelected], async ([new_start, new_sort, new_tagsSelected], added, removed) => {
  console.log('watcher');
  
  $patcher.change('/shorts', {
    isOnlyQuery: true,
    tags: new_tagsSelected,
    sort: new_sort,
  });

  shortsDataStatus.value = 'pending';

  let dt = await loadData();

    
  if(dt.length == 0)
    isEnded.value = true;  


  if(new_start == 0){
    shortsData.value.items = [];
    isEnded.value = false;
  }

  shortsData.value.items = [...shortsData.value.items, ...dt.items]
  if(dt.items_tag?.length) shortsData.value.items_tag = dt.items_tag;

  shortsDataStatus.value = 'success';

  // $adv.apply();
  
  useHead(useNuxtApp().$head.getGrowQuestions( (getTagsSimpleTrKey(tags.value, tagsSelected.value).map((x) => t(x))).join(', ') ));

}, {deep: true})



useIntersectionObserver(
  elInfinityScroll,
  ([{isIntersecting}]) => {
    if(isIntersecting){
      console.log('infinity scroll event');        
      if(shortsDataStatus.value === 'success' && !isEnded.value)
        start.value = start.value + limit.value;
    }
  },
  { distance: 10 }
)

useHead(useNuxtApp().$head.getGrowQuestions( (getTagsSimpleTrKey(tags.value, tagsSelected.value).map((x) => t(x))).join(', ') ));


onUnmounted(() => {
  useTopbarHiddenMobile().value = false;
  useWarningMigrateHidden().value = false;
  useNavbarThemeRequired().value = null;
});

 
</script>






<style scoped>

h1{
  margin-right: auto;
}

.add_filter_box{
  display: flex;
  margin-left: auto;
}

.headerow{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}

@container pb (max-width: 700px) {
  .headerow{

  }
  .headerow > .search{
    order: 1;
    width: 100%;
  }
  .headerow > .stags{
    order: 0;
    margin: 0;
  }
  .headerow > .sort{
    order: 2;
    justify-content: flex-end;
    width: 100%;
    margin: 0;
  }
}


/*  */

.videolist {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: hidden;
  justify-content: left;
  gap: 1rem;
  transition: all 0.3s ease;  
}

@container pb (max-width: 560px) { 
  .videolist{
    justify-content: space-between;
  }

  .videolist:deep(.video_short){
    width: calc(100%/2 - 0.5rem);
  }
}


/* over-player */

.over-player-pc{
  container: pc / inline-size;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 11100;
  height: 100dvh;
  width: 100%;
}
.over-player{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgb(0 0 0 / 84%);
  z-index: 11100;
  height: 100dvh;
  width: 100%;
  padding-top: 40px;    
}
@container pc (max-width: 500px) { 
  .over-player{
    padding-top: 0;
  }
}

</style>